import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['video', 'control']

  connect() {
    const video = this.videoTarget;
    let pausedState = true;

    if (video) {
      // if the video has the autoplay attribute
      // set the paused state to false since the video will start playing automatically
      pausedState = !video.hasAttribute('autoplay');
    }

    this.state = {
      paused: pausedState,
    }
  }

  toggle(event) {
    const video = this.videoTarget;
    const control = this.controlTarget;

    if (!video) return;

    if (this.state.paused || video.ended) {
      video.play();
      this.state.paused = false

      if (!control) return;
      control.classList.remove('paused');
    } else {
      video.pause();
      this.state.paused = true

      if (!control) return;
      control.classList.add('paused');
    }
  }
}
